<template>
    <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">

            <!-- TODO: make this a nice "user card" with avatar (customized photo if we have it, or generic user icon), display name, and email address ; like login page, but without the 'switch account' button since setup is for a specific user -->
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- <h1 class="text-h6 font-weight-light text-center">{{ displayName }}</h1> -->
                    <!-- <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <font-awesome-icon :icon="['fas', 'user']"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ displayName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list> -->
                    <v-simple-table dense class="mt-4">
                        <template #default>
                            <tbody>
                                <tr>
                                    <td style="width: 32px;">
                                        <font-awesome-icon :icon="['fas', 'user']" size="2x"/>
                                    </td>
                                    <td>
                                        <p class="text-body-1 mb-0">{{ displayName }}</p>
                                        <p class="text-caption mb-0">{{ email }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>

            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isViewReady">
                <v-row justify="center" class="py-5">
                    <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                        <v-card elevation="4" class="pa-0" v-if="!isError">
                            <v-app-bar :style="cardTitleBarStyle" flat>
                                <v-toolbar-title :style="cardTitleBarTextStyle">Authentication setup</v-toolbar-title>
                                <v-progress-linear
                                    :active="isLoading"
                                    :indeterminate="isLoading"
                                    absolute
                                    bottom
                                    :color="accentColor"
                                ></v-progress-linear>
                            </v-app-bar>
                            <v-card-text>
                                <!-- TODO: temporarily hiding these checkmarks because users reported them confusing and they wanted to click on them; we just need to find another way to show what's required and already set vs not already set that the user has to do -->
                                <!-- <template v-if="Array.isArray(requiredAuthnMechanismList) && requiredAuthnMechanismList.length > 0">
                                    <p class="text-overline mb-0">Required</p>
                                    <v-list class="py-0">
                                        <v-list-item v-for="(item, idx) in requiredAuthnMechanismList" :key="idx">
                                            <v-list-item-action>
                                                <font-awesome-icon :icon="['fas', 'check']" v-if="Array.isArray(activatedAuthnMechanismList) && activatedAuthnMechanismList.includes(item)"/>
                                                <font-awesome-icon :icon="['far', 'square']" v-if="Array.isArray(activatedAuthnMechanismList) && !activatedAuthnMechanismList.includes(item)"/>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ authnMechanismDisplayName[item] }}
                                                </v-list-item-title>
                                                < ! - - <v-list-item-subtitle>
                                                    {{ item.tooltip }}
                                                </v-list-item-subtitle> - - >
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </template> -->

                                <!-- TODO: temporarily hiding this optional header; it's going to be part of the above-mentioned change about showing user the situation a different way -->
                                <!-- <template v-if="Array.isArray(optionalAuthnMechanismList) && optionalAuthnMechanismList.length > 0">
                                <p class="text-overline mb-0">Optional</p>
                                </template> -->

                                <!-- <p class="text-overline mb-0">Login method</p>
                                <p class="mb-0 pb-0">
                                    <span>{{ authnMechanismDisplayName[authnMechanism] }}</span>
                                    < ! -- <v-btn class="blue--text no-print" @click="dialogChangeAuthnMechanism = true" icon>
                                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                    </v-btn> - - >
                                </p> -->
                                <!-- <v-list class="py-0">
                                    <v-list-item-group v-model="authnMechanismSelected">
                                        <v-list-item v-for="(item, idx) in authnMechanismChoices" :key="idx">
                                        <template #default="{ active }">
                                            <v-list-item-action>
                                                <v-checkbox :input-value="active"/>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.text }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ item.tooltip }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list> -->
                                <!-- Selected: {{ authnMechanismChoices[authnMechanismSelected].text }} -->
                                <!-- password settings -->
                                <template v-if="authnMechanismAvailableList.includes('password') && !activatedAuthnMechanismList.includes('password') && passwordResetToken">
                                <p class="mb-6 mt-6 text-body-1">Set a password to protect your account.</p>
                                <!-- <p class="text-overline mb-0 mt-10">Password</p> -->
                                <!-- <p class="mb-0 pb-0">
                                    A typical way to login. Risks include weak passwords, stolen passwords, and phishing attacks. To use passwords more securely, use the password generator to generate a strong password and store it with a password manager integrated with your browser.
                                </p> -->
                                <!-- <p class="mb-6 pb-0">
                                    <v-btn outlined color="indigo" @click="dialogChangePassword = true">Set password</v-btn>
                                </p> -->
                                <!-- <DialogChangePassword v-model="dialogChangePassword" :token="passwordResetToken"/> -->
                                <ChangePasswordForm :token="passwordResetToken" @changed="afterPasswordSetup"/> <!-- TODO: capture @cancelled event?  or maybe pass a parameter to this form to hide the cancel button instead? because this shouldn't be skippable... -->
                                </template>
                                <!-- loginshield settings -->
                                <template v-if="authnMechanismAvailableList.includes('loginshield') && false"> <!-- NOTE: temporarily disabled loginshield setup until we complete that integration -->
                                    <p class="text-overline mb-0 mt-10">
                                        One-tap Login
                                    </p>
                                    <!-- <p class="mb-0 pb-0">
                                        Secure and convenient replacement for passwords. Login with just one tap.
                                    </p> -->
                                    <!-- <p class="mb-0 pb-0" v-if="authnMechanism !== 'loginshield'">
                                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="orange--text"/>
                                        <span class="ml-2">Your account is not currently protected by LoginShield</span>
                                    </p> -->
                                    <template v-if="!loginshield">
                                        <p class="mb-0 pb-0">
                                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="orange--text"/>
                                            <span class="ml-2">Service temporarily unavailable.</span>
                                        </p>
                                    </template>
                                    <template v-if="loginshield && !loginshield.registered && !loginshield.confirmed">
                                        <p class="mb-0 pb-0">
                                        Click the button to setup LoginShield for this account:
                                        </p>
                                        <v-btn color="indigo white--text" @click="activateLoginShield">
                                            <!-- <font-awesome-icon icon="check" fixed-width/><span class="ml-2">Activate</span> -->
                                            Activate LoginShield
                                            <!-- <v-img :src="require('@/assets/logo/loginshield/LoginShield_transparent_48x48.png')" :width="18" :height="18" contain/>
                                            <span class="ml-2">Activate</span> -->
                                        </v-btn>
                                    </template>
                                    <template v-if="loginshield && loginshield.registered && !loginshield.confirmed">
                                        <p class="mb-0 pb-0">
                                        You need to login once with LoginShield before you can use it as your regular login method:
                                        </p>
                                        <!-- <v-btn tile elevation="4" class="green white--text" @click="activateLoginShield">
                                            <font-awesome-icon icon="check" fixed-width/><span class="ml-2">Activate</span>
                                        </v-btn> -->
                                        <v-btn color="indigo white--text" @click="activateLoginShield">
                                            <!-- <font-awesome-icon icon="check" fixed-width/><span class="ml-2">Activate</span> -->
                                            Activate LoginShield
                                            <!-- <v-img :src="require('@/assets/logo/loginshield/LoginShield_transparent_48x48.png')" :width="18" :height="18" contain/>
                                            <span class="ml-2">Activate</span> -->
                                        </v-btn>
                                    </template>
                                    <template v-if="loginshield && loginshield.registered && loginshield.confirmed">
                                        <!-- <p class="mb-0 pb-0">
                                        Ready to activate LoginShield?
                                        </p>
                                        <v-btn tile elevation="4" class="green white--text" @click="activateLoginShield">
                                            <font-awesome-icon icon="check" fixed-width/><span class="ml-2">Activate</span>
                                        </v-btn> -->
                                        <v-switch
                                            :value="loginshield.activated"
                                            @input="toggleLoginShieldActivation($event.target.value)"
                                            color="blue"
                                            hide-details
                                        >
                                        <template v-slot:label>
                                            <span style="margin-left: 4px">Protect this account with LoginShield</span>
                                        </template>
                                        </v-switch>
                                    </template>
                                    <!-- <template v-if="loginshield && loginshield.registered && loginshield.confirmed && loginshield.activated">
                                        <p class="mb-0 pb-0">
                                        LoginShield is activated and protecting your account.
                                        </p>
                                    </template> -->
                                </template>

                                <template v-if="Array.isArray(remainingRequiredAuthnMechanismList) && remainingRequiredAuthnMechanismList.length === 0">
                                    <v-btn class="mt-6" :style="primaryButtonStyle" @click="continueAfterSetup">Continue</v-btn>
                                </template>
                            </v-card-text>
                        </v-card>
                        <template v-if="requestError">
                            <v-alert type="error">
                                Bad request
                            </v-alert>
                            <!-- TODO: link to return to login page for this realm? or to client app for this realm? -->
                        </template>
                    </v-col>
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>
<style>
.v-data-table {
    background-color: #efefef !important; /* hide data table white background by matching page background */
}
</style>
<script>
import { mapState, mapGetters } from 'vuex';
import { INTENT_PREFS, INTENT_REDIRECT, RESPONSE_TYPE_INTENT } from '@/sdk/loginfront/login_api_constants.js'; // '@loginfront/login-api-constants-js';
// import { toMillis, toText } from '@/sdk/time';
// import DialogChangePassword from '@/components/DialogChangePassword.vue';
import ChangePasswordForm from '@/components/ChangePasswordForm.vue';

/*
This activity requires an authenticated user and allows the user to manage
their preferred authentication mechanisms and activate new authentication
mechanisms. A new user who hasn't activated all required mechanisms yet will be automatically
authenticated by the server if they arrive with a setup token from the client
application, typically during their sign up sequence. Otherwise, a non-authenticated
user must be redirected to sign in first and then return here.
*/

export default {
    components: {
        // DialogChangePassword,
        ChangePasswordForm,
    },
    data: () => ({
        setupRequestId: null,
        isViewReady: false,

        // from setup request status
        intent: null, // should be 'setup' for this activity
        alias: null, // user's login username; not editable in this activity
        email: null, // user's email address; not editable in this activity
        displayName: null, // user's display name; not editable in this activity
        requiredAuthnMechanismList: null,
        optionalAuthnMechanismList: null,
        activatedAuthnMechanismList: null,
        preferredAuthnMechanismList: null,
        remainingRequiredAuthnMechanismList: null,
        remainingOptionalAuthnMechanismList: null,

        // from realm policy
        authnMechanismAvailableList: [],
        authnMechanismChoices: [],
        authnMechanismDisplayName: {
            email: 'Email', // send code or link by email
            password: 'Password',
            loginshield: 'LoginShield', // TODO: change display name to "One-Tap Login" or "Password-less" or "Private Key" or "Digital Signature" or "Authenticator" in accordance with mering LoginFront features into LoginShield where LoginShield is not just the app but also passwords, OTP, etc. so this entire platform will be LoginShield, and we need to find something more descriptive for the use of the LoginShield app with private keys
            otp: 'OTP', // one-time password
            sms: 'SMS', // send code or link by sms
            fingerprint: 'Fingerprint',
        },
        // authnMechanismTooltip: {
        //     password: 'A typical way to login. Risks include weak passwords, stolen passwords, and phishing attacks.',
        //     loginshield: 'Secure and convenient replacement for passwords. Login with just one tap.',
        // },
        authnMechanism: null, // 'password' or 'loginshield'
        authnMechanismSelected: null, // integer, index of selected choice in authnMechanismChoices

        loginshield: null,

        // dialogChangePassword: false,
        passwordResetToken: null, // required to set a new password without first entering the old password

        requestError: false,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            palette: (state) => state.palette,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        password() {
            if (this.user.info && this.user.info.password) {
                return this.user.info.password;
            }
            return '(no password)';
        },
        displayName() {
            return this.realmInfo?.display_name;
        },
        // name: {
        //     get() {
        //         if (this.user.name) {
        //             return this.user.name;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         this.$store.dispatch('editUser', { name: value });
        //     },
        // },
        // email: {
        //     get() {
        //         if (this.user.email) {
        //             return this.user.email;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
        //         this.$store.dispatch('editUser', { email: value });
        //     },
        // },
        isError() {
            return this.requestError;
        },
    },
    watch: {
    },
    methods: {
        async init() {
            console.log('Setup.vue: init');
            // if a client application redirected a user here to login, there should be
            // a login token in the query
            if (this.$route.query.token) {
                await this.setupWithToken(this.$route.query.token);
                this.isViewReady = true;
                return;
            }

            // after we process the token, we replace it in the query with an interaction id
            // for the setup request
            if (this.$route.query.i) {
                await this.loadSetupRequest(this.$route.query.i);
                this.isViewReady = true;
                return;
            }

            // if user arrived without token or interaction id, show an error message
            this.requestError = true;
            this.isViewReady = true;
        },
        async loadAuthenticationPolicy() {
            try {
                this.$store.commit('loading', { loadAuthenticationPolicy: true });
                // load defaults from server
                const policy = await this.$client.realm(this.$route.params.realm).authn.getPolicy();
                // available authentication mechanisms
                this.authnMechanismAvailableList = policy.authnMechanismList ?? [];
                this.authnMechanismChoices = this.authnMechanismAvailableList.map((item) => ({
                    value: item,
                    text: this.authnMechanismDisplayName[item] ?? item,
                    // tooltip: this.authnMechanismTooltip[item],
                    enabled: this.user?.info?.authn === item, // this.authnMechanismAvailableList.findIndex(item) > -1,
                }));
                this.authnMechanism = this.user?.info?.authn ?? 'password';
                this.authnMechanismSelected = this.authnMechanismChoices.findIndex((item) => item.value === this.authnMechanism);
            } catch (err) {
                console.error('loadAuthenticationPolicy failed', err);
            } finally {
                this.$store.commit('loading', { loadAuthenticationPolicy: false });
            }
        },
        async setupWithToken(token) {
            try {
                this.$store.commit('loading', { setupWithToken: true });
                const setupRequest = await this.$client.realm(this.$route.params.realm).authn.setup({ token });

                await this.prepareView(setupRequest);

                // if (error) {
                //     // remove token from url and show an alert that something went wrong with the login request
                //     this.$router.replace({ name: 'realm-login', params: { realm: this.$route.params.realm } });
                //     this.startError = true;
                //     return;
                // }

                const query = { i: this.setupRequestId }; // TODO: also include all other query parameters, EXCEPT for 'token' ?
                this.$router.replace({
                    name: 'realm-profile-setup',
                    query,
                    step: 'start',
                    t: Date.now(),
                });
            } catch (err) {
                console.error('setupWithToken failed', err);
            } finally {
                this.$store.commit('loading', { setupWithToken: false });
            }
            /*
            if (intent === 'activate-loginshield') {
                this.intent = intent;

                // new user registration or reset
                console.log('login: activate loginshield mode');
                this.loginUsernameInput = false;
                this.loginWithLoginShield = true;
                this.isActivatingLoginShield = true;
                setTimeout(() => {
                    this.$nextTick(() => this.startSetupShield({
                        mode: 'activate-loginshield',
                        interactionId: this.interactionId,
                        nextRoute: this.next,
                        username: this.username,
                        rememberMe: true, // this.isRememberMeChecked
                    }));
                }, 1);
                return;
            }
            */

            // TODO: server reports that user could continue as they are
            /*
            if (authenticated) {
            //     // TODO: show a view with user's current profile, allow user to continue as current user or switch account;  to continue as current user we need an API call to server to confirm that choice and get the redirect link, that's the part that isn't implemented yet on server, and the UI described here isn't implemeneted yet on this page
            }
            */

            // if (this.isAuthenticated) {
            //     console.log('login with token, but already authenticated!!! prompt user to continue as current user, or to switch users');
            // }

            /*
            if (mechanism === 'username') {
                this.loginUsernameInput = true;
                this.loginPasswordInput = false;
                this.loginWithLoginShield = false;
            }
            if (mechanism === 'password') {
                this.loginUsernameInput = false;
                this.loginPasswordInput = true;
                this.loginWithLoginShield = false;
                this.$nextTick(() => this.$refs.passwordField.focus());
            } else if (mechanism === 'loginshield') {
                this.loginUsernameInput = false;
                this.loginWithLoginShield = true;
                this.startSetupShield({
                    username: this.username,
                    interactionId: this.interactionId,
                    nextRoute: this.next,
                    rememberMe: true, // this.isRememberMeChecked
                });
            } else {
                this.startError = true;
                this.resetLoginForm();
            }
            */
        },
        async loadSetupRequest(setupRequestId) {
            try {
                this.$store.commit('loading', { loadSetupRequest: true });
                const setupRequest = await this.$client.realm(this.$route.params.realm).authn.setup({ id: setupRequestId });
                console.log(`loadSetupRequest loaded: ${JSON.stringify(setupRequest)}`);
                this.setupRequestId = setupRequestId;

                await this.prepareView(setupRequest);
            } catch (err) {
                console.error('loadSetupRequest failed', err);
            } finally {
                this.$store.commit('loading', { loadSetupRequest: false });
            }
        },
        async prepareView(setupRequest) {
            const {
                intent,
                setup_request_id: setupRequestId,
                required_authn_mechanism_list: requiredAuthnMechanismList,
                optional_authn_mechanism_list: optionalAuthnMechanismList,
                activated_authn_mechanism_list: activatedAuthnMechanismList,
                preferred_authn_mechanism_list: preferredAuthnMechanismList,
                remaining_required_authn_mechanism_list: remainingRequiredAuthnMechanismList,
                remaining_optional_authn_mechanism_list: remainingOptionalAuthnMechanismList,
                password_reset_id: passwordResetToken,
                alias,
                // require_alias: requireAlias, // must be `true` for a setup intent
                email,
                display_name: displayName,
                redirect,
                // next,
                // error,
                // authenticated,
            } = setupRequest;

            if (intent) {
                this.intent = intent; // should be 'setup' for this activity
            }
            if (setupRequestId) {
                this.setupRequestId = setupRequestId;
            }
            if (passwordResetToken) {
                this.passwordResetToken = passwordResetToken;
            }
            if (alias) {
                this.alias = alias; // username, for realms that identify users this way instead of email address
            }
            if (email) {
                this.email = email;
            }
            if (displayName) {
                this.displayName = displayName;
            }
            this.requiredAuthnMechanismList = requiredAuthnMechanismList ?? [];
            this.optionalAuthnMechanismList = optionalAuthnMechanismList ?? [];
            this.activatedAuthnMechanismList = activatedAuthnMechanismList ?? [];
            this.preferredAuthnMechanismList = preferredAuthnMechanismList ?? [];
            this.remainingRequiredAuthnMechanismList = remainingRequiredAuthnMechanismList ?? [];
            this.remainingOptionalAuthnMechanismList = remainingOptionalAuthnMechanismList ?? [];

            if (redirect) {
                console.log(`setupWithToken: redirect ${redirect}`);
                window.location.href = redirect;
                return;
            }
            // if (next) {
            //     console.log(`setupWithToken: next route ${JSON.stringify(next)}`);
            //     this.$router.replace(next);
            //     return;
            // }
            await this.loadAuthenticationPolicy();
        },
        async loadUserLoginShieldStatus() {
            try {
                this.$store.commit('loading', { loadUserLoginShieldStatus: true });
                this.loginshield = await this.$client.realm(this.$route.params.realm).authn.getLoginShieldSettings();
            } catch (err) {
                this.loginshield = null;
                console.error('failed to load user loginshield status', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load some information', message: 'The settings shown may not be accurate' });
            } finally {
                this.$store.commit('loading', { loadUserLoginShieldStatus: false });
            }
        },
        async toggleLoginShieldActivation(value) {
            this.$bus.$emit('snackbar', { type: 'info', message: `switch is now ${value}` });
            if (value) {
                this.activateLoginShield();
            } else {
                this.deactivateLoginShield();
            }
        },
        /**
         * This is used for both first-time activation and also when user
         * re-activates LoginShield; we send the request to the server, and
         * the server will check the user's LoginShield settings and decide
         * what needs to be done next.
         */
        async activateLoginShield() {
            try {
                this.$store.commit('loading', { activateLoginShield: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.edit({ authn: 'loginshield' });
                console.log(`activateLoginShield response ${JSON.stringify(response)}`);
                // this.$bus.$emit('snackbar', { type: 'info', headline: 'ok...' });
                const { isEdited, nextRoute, redirect } = response;
                if (isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Activated LoginShield' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to activate LoginShield' });
                }
                if (nextRoute) {
                    this.$router.push(nextRoute);
                    return;
                }
                if (redirect) {
                    window.location.href = redirect;
                }
            } catch (err) {
                this.loginshield = null;
                console.error('failed to activate loginshield', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to activate LoginShield', message: 'Please contact customer support for assistance' });
            } finally {
                this.$store.commit('loading', { activateLoginShield: false });
            }
        },
        async deactivateLoginShield() {
            try {
                this.$store.commit('loading', { deactivateLoginShield: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.edit({ authn: 'password' });
                console.log(`deactivateLoginShield response ${JSON.stringify(response)}`);
                // this.$bus.$emit('snackbar', { type: 'info', headline: 'ok...' });
                const { isEdited, nextRoute, redirect } = response;
                if (isEdited) {
                    this.$bus.$emit('snackbar', { type: 'warn', headline: 'Deactivated LoginShield' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to deactivate LoginShield' });
                }
                if (nextRoute) {
                    this.$router.push(nextRoute);
                    return;
                }
                if (redirect) {
                    window.location.href = redirect;
                }
            } catch (err) {
                this.loginshield = null;
                console.error('failed to deactivate loginshield', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to deactivate LoginShield', message: 'Please contact customer support for assistance' });
            } finally {
                this.$store.commit('loading', { deactivateLoginShield: false });
            }
        },
        async afterPasswordSetup() {
            // reload the setup status
            await this.loadSetupRequest(this.setupRequestId);

            // if there aren't any other required mechanisms to set up, and there aren't any optional mechanisms at all, don't show a summary and automatically redirect user back to where they came from
            if (this.remainingRequiredAuthnMechanismList.length === 0 && this.optionalAuthnMechanismList.length === 0 && this.authnMechanism) {
                await this.continueAfterSetup();
            }
        },
        async continueAfterSetup() {
            try {
                this.$store.commit('loading', { continueAfterSetup: true });

                // the 'finish' intent causes server to return a redirect URL if the setup is complete
                const setupRequest = await this.$client.realm(this.$route.params.realm).authn.setup({ id: this.setupRequestId, intent: 'finish' });
                const { type } = setupRequest;
                if (type === RESPONSE_TYPE_INTENT) {
                    const { intent } = setupRequest;
                    if (intent === INTENT_REDIRECT) {
                        const { redirect } = setupRequest;
                        if (redirect) {
                            // since we only redirect the user in response to a button action, we
                            // use `push` so that user can return to this same page and try again
                            // if they use the back button
                            if (typeof window.location.push === 'function') {
                                window.location.push(redirect);
                            } else {
                                // TODO: also show the link on the page for user to click
                                window.location.href = redirect;
                            }
                            return;
                        }
                    }
                    if (intent === INTENT_PREFS) {
                        this.$router.replace({ name: 'realm-user-preferences-authn', params: { realm: this.$route.params.realm } });
                        return;
                    }
                }
                this.$bus.$emit('snackbar', { type: 'warn', headline: 'Authentication settings not fully configured' });
                await this.prepareView(setupRequest);
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to redirect back to application' });
            } finally {
                this.$store.commit('loading', { continueAfterSetup: false });
            }
        },
        // a 'cancel' button or link can call this function to return user to client application without completing setup
        async cancel() {
            try {
                this.$store.commit('loading', { continueAfterSetup: true });

                // the 'cancel' intent causes server to return a redirect URL back to client application without completing setup
                const setupRequest = await this.$client.realm(this.$route.params.realm).authn.setup({ id: this.setupRequestId, intent: 'cancel' });

                const { redirect } = setupRequest;
                if (redirect) {
                    // since we only redirect the user in response to a button action, we
                    // use `push` so that user can return to this same page and try again
                    // if they use the back button
                    if (typeof window.location.push === 'function') {
                        window.location.push(redirect);
                    } else {
                        // TODO: also show the link on the page for user to click
                        window.location.href = redirect;
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'warn', headline: 'Request failed' });
                    await this.prepareView(setupRequest);
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to redirect back to application' });
            } finally {
                this.$store.commit('loading', { continueAfterSetup: false });
            }
        },
    },
    mounted() {
        this.init();
        // this.loadUserLoginShieldStatus(); // TODO: only load this if user is authenticated... or else server will respond with 401 unauthorized since user must be signed in to see their settings
    },
};
</script>
